// Font Families
@font-face {
	font-family: 'Circular Regular';
	src: url('../../fonts/Circular/CircularRegular.woff2') format('woff2'),
		url('../../fonts/Circular/CircularRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Circular Bold';
	src: url('../../fonts/Circular/CircularBold.woff2') format('woff2'),
		url('../../fonts/Circular/CircularBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

// Fonts
:root {
	--font-regular: 'Circular Regular', sans-serif;
	--font-bold: 'Circular Bold', sans-serif;
}

// TODO: add FFMark Bold file and move below line inside root above
//   --font-ffmark-bold: 'FFMark Bold', sans-serif;
