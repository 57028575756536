@use 'styles/functions' as *;

.default {
	text-align: center;
	display: inline-block;
}

.xSmall {
	@extend .default;
	width: rem(10px);
	height: rem(10px);
}

.small {
	@extend .default;
	width: rem(16px);
	height: rem(16px);
}

.medium {
	@extend .default;
	width: rem(24px);
	height: rem(24px);
}

.large {
	@extend .default;
	width: rem(48px);
	height: rem(48px);
}
