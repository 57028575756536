:root {
	--primary-upgrad-red: #e95454;
	--primary-upgrad-blue: #4d8af0;
	--primary-upgrad-green: #22af73;
	--primary-dark-blue: #091e42;
	--primary-white: #ffffff;
	--primary-black: #000;

	--secondary-green: #02a971;
	--secondary-blue: #4f8afb;
	--secondary-violet: #9473f2;
	--secondary-purple: #b64f97;
	--secondary-orange: #e77059;
	--secondary-yellow: #f6c24c;
	--secondary-light-blue: #63c3fa;
	--secondary-light-grey: #e8e8e8;
	--secondary-dark-grey: #4a4a4a;
	--secondary-light-blue-2: #f5f8fe;
	--secondary-light-blue-3: #93d4fa;
	--secondary-pastel-green: #ecf8f5;
	--secondary-red: #eb5760;
	--secondary-dark-blue: #063855;

	--skyblue: #2badf8;
	--black1: #0000001a;
	--black2: #1A202C;
	--black3: #4A5568;
	--purple: #9473f2;
	--magenta: #b64f97;
	--yellow: #f3b119;
	--green1: #02a971;
	--green2: #22af73;
	--grey1: #45526c;
	--grey2: #0000001f;
	--grey3: #7c8698;
	--grey4: #343434;
	--grey5: #9c9c9c;
	--grey6: #f0f0f0;
	--grey7: #e7e7e7;
	--grey8: #F4F5F7;
	--red1: #EA5C5C;

	--gradient-dark-orange-red: linear-gradient(to right, #fa8888, #f82c4e);
	--gradient-dark-purple-violet: linear-gradient(to right, #d69dc5, #9473f2);
	--gradient-dark-orange-purple: linear-gradient(to right, #f3a0a0, #9a407f);
	--gradient-dark-blue-blue: linear-gradient(to right, #86d0fb, #4f8afb);
	--gradient-dark-green-blue: linear-gradient(to right, #67cbaa, #2badf8);

	//TODO: add light gradients and overlays
}
