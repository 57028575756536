@use 'styles/functions' as *;

.goBackToDashboardCta {
	background: var(--primary-white);
	border: rem(2px) solid var(--primary-upgrad-red);
	box-shadow: rem(0px) rem(2px) rem(8px) rgba(9, 30, 66, 0.04),
		rem(0px) rem(12px) rem(32px) rgba(9, 30, 66, 0.12);
	border-radius: rem(8px);
	font-weight: 500;
	font-size: var(--font-16);
	line-height: rem(27px);
	letter-spacing: -0.00533333em;
	color: var(--primary-upgrad-red);
	padding: rem(8px) rem(16px) rem(8px) rem(12px);
	width: calc(100% - 40px);
	transform: translateX(-50%);
	left: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: rem(16px);
}
