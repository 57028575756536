@use 'functions';
@use 'shadow-generator';
@use 'radius-generator';
@use 'space-generator';
@use 'typography/index';
@use 'color';
@use 'mixins';
@import 'responsive';

// TODO: check if normalize is needed after adding ant design
// @import-normalize;

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
	font-feature-settings: 'tnum' 0;
}

body {
	margin: 0;

	font-family: var(--font-regular);
	// font-feature-settings: "tnum" off;
	// word-spacing: -5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.height-100 {
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

// TODO: to check if this is the correct place
.ant-tabs-tab:hover {
	background-color: #f0f0f0;
}
.ant-tabs-nav {
	background-color: #fff;
	color: #4a4a4a;
	font-weight: bold;
}
// Filter
.filter-card-header {
	.ant-card-head-title,
	.ant-card-extra {
		padding-top: 12px;
		padding-bottom: 12px;
	}
}
.collapse-container {
	.ant-collapse-header {
		padding: 16px 47px 16px 20px !important;
	}
}
.filters-container {
	.ant-collapse-icon-position-right
		> .ant-collapse-item
		> .ant-collapse-header
		.ant-collapse-arrow {
		right: 20px;
	}
}

// SEARCH-BAR ANT override
.ant-select-multiple .ant-select-selection-placeholder {
	left: 44px;
}
// BUTTON LINK ANT oveeride
.ant-btn-link {
	color: #3679fb;
}
// PROFILE DROPDOWN
.profile-dropdown {
	.ant-popover-arrow-content {
		background-color: var(--black2);
	}
}

.change-password-form {
	.ant-form-item-explain-error {
		font-size: var(--font-12);
	}
}
// LOGIN MODAL
._1wtmdzy1un8c-SOqDcQS2i {
	margin: 0;
}
// GENERAL CLASSES
.visible {
	visibility: visible;
}
.hidden {
	visibility: hidden;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
